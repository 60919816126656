<template>
  <span>
    <b-dropdown id="filters-dropdown" no-flip>
      <template #button-content>
        Filtering {{ rowsShow.length }} out of {{ rows.length }}
      </template>
      <span v-for="row in select" :key="'select-id' + row.property">
        <b-dropdown-item-button v-b-toggle="'sidebar-' + row.property">{{row.name}} <b-badge v-if="selected[row.property].length != 0" variant="primary">{{selected[row.property].length}}</b-badge></b-dropdown-item-button>
      </span>
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-item-button @click="filterReset"><strong>show all</strong></b-dropdown-item-button>
    </b-dropdown>
    <div v-for="row in select" :key="'select-id' + row.property" class="mr-2 mb-3">
      <b-sidebar width="50%" :id="'sidebar-' + row.property" :title="row.property.charAt(0).toUpperCase() + row.property.slice(1)" backdrop backdrop-variant="transparent" shadow>
        <div class="px-3 py-2 text-center" align-self="center" align-v="center">
          <b-button-group b-button-group class="mt-3 text-center">
              <b-button variant="outline-primary" class="text-center" v-on:click="setAll(row.property)">all</b-button>
              <b-button variant="outline-primary" class="text-center" v-on:click="setNone(row.property)">none</b-button>
          </b-button-group>
          <b-card class="m-3">
          <b-form-checkbox-group
                  v-model="$data.selected[row.property]"
                  :options="$data.options[row.property]"
                  button-variant="light"
                  size="md"
                  class="d-flex flex-column mx-auto text-left"
                  switches
              ></b-form-checkbox-group>
          </b-card>
        </div>
      </b-sidebar>
    </div>
  </span>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'FilterInterface',
  props: ['namespace', 'rows', 'staticDataProperties', 'includeTaxonomy'],
  computed: {
    rowsShow: function () {
      let rows = []
      rows = this.rows
      _.each(this.select, selection => {
        if (selection.direct) {
          if (this.selected[selection.property].length > 0) {
            rows = _.filter(rows, x => {
              let result = false
              _.each(this.selected[selection.property], item => {
                if (x[selection.property] === item) {
                  result = true
                }
              })
              return result
            })
          }
        }
        if (selection.graph) {
          if (this.selected[selection.property].length > 0) {
            rows = _.filter(rows, x => {
              let result = false
              if (Array.isArray(x[selection.graph])) {
                _.each(this.selected[selection.property], item => {
                  if (selection.sectors) {
                    if (_.find(x[selection.graph], ['sector', item])) {
                      result = true
                    }
                  } else {
                    if (_.find(x[selection.graph], ['id', item])) {
                      result = true
                    }
                  }
                })
              } else {
                _.each(this.selected[selection.property], item => {
                  if (x[selection.graph].id === parseInt(item)) {
                    result = true
                  }
                })
              }
              return result
            })
          }
        }
        if (selection.taxonomy) {
          if (this.selected[selection.property].length > 0) {
            rows = _.filter(rows, x => {
              let result = false
              _.each(this.selected[selection.property], item => {
                if (_.find(x.taxonomy, ['id', item])) {
                  result = true
                }
              })
              return result
            })
          }
        }
      })
      this.$emit('input', rows)
      return rows
    },
    select: function () {
      let select = []
      _.each(this.staticDataProperties, property => {
        this.$data.options[property.property] = this.staticData[property.property].map(x => {
          return { value: x.id, text: x.name }
        })
        const selectRow = {
          ...property
        }
        select.push(selectRow)
      })
      if (this.includeTaxonomy) {
        _.each(this.staticDataTaxonomy, (value) => {
          this.$data.options[value.key] = value.children.map(x => {
            return { value: x.id, text: x.name }
          })
          const selectRow = {
            name: value.name,
            property: value.key,
            taxonomy: true

          }
          select.push(selectRow)
        })
      }
      select = _.sortBy(select, 'name')
      return select
    },
    staticData: function () {
      const staticData = {}
      _.each(this.staticDataProperties, sdp => {
        let set = []
        _.each(this.rows, row => {
          if (sdp.direct) {
            set.push({ id: row[sdp.property], name: row[sdp.property] })
          } else if (sdp.tree) {
            set.push({ id: row[sdp.property].id, name: row[sdp.property].name })
          } else if (sdp.sectors) {
            if (row[sdp.property].length > 0) {
              row[sdp.property].forEach(sector => {
                set.push({ id: sector.sector, name: sector.sector })
              })
            }
          } else {
            console.log(sdp.property)
            if (row[sdp.property].length > 0) {
              row[sdp.property].forEach(item => {
                set.push({ id: item.id, name: item.name })
              })
            }
          }
        })
        set = _.uniqBy(set, 'id')
        set = _.sortBy(set, 'name')
        if (set.length > 0) {
          staticData[sdp.property] = set
        }
      })
      return staticData
    },
    staticDataTaxonomy: function () {
      let parents = []
      if (this.includeTaxonomy) {
        const flatData = []
        _.each(this.rows, row => {
          if (row.taxonomy.length > 0) {
            row.taxonomy.forEach(taxonomy => {
              taxonomy.parents.forEach(parent => {
                flatData.push({ parent_id: parent.id, parent_name: parent.name, id: taxonomy.id, name: taxonomy.name })
                parents.push(parent)
              })
            })
          }
        })
        parents = _.uniqBy(parents, 'id')
        parents.forEach(parent => {
          parent.key = _.camelCase(parent.name)
          parent.children = _.filter(flatData, { parent_id: parent.id })
          parent.children = _.uniqBy(parent.children, 'id')
        })
      }
      return parents
    }
  },
  created: function () {
    const filterSelected = this.$store.state.filterSelected
    console.log('filterSelected', filterSelected)
    if (filterSelected[this.namespace]) {
      this.selected = filterSelected[this.namespace]
    }
  },
  data () {
    const data = {
      selected: {},
      options: {}
    }
    _.each(this.staticDataProperties, property => {
      data.selected[property.property] = []
      data.options[property.property] = []
    })
    if (this.includeTaxonomy) {
      const flatData = []
      let parents = []
      _.each(this.rows, row => {
        if (row.taxonomy.length > 0) {
          row.taxonomy.forEach(taxonomy => {
            taxonomy.parents.forEach(parent => {
              flatData.push({ parent_id: parent.id, parent_name: parent.name, id: taxonomy.id, name: taxonomy.name })
              parents.push(parent)
            })
          })
        }
      })
      parents = _.uniqBy(parents, 'id')
      parents.forEach(parent => {
        data.selected[_.camelCase(parent.name)] = []
        data.options[_.camelCase(parent.name)] = []
      })
    }
    return data
  },
  methods: {
    setAll: function (selectionName) {
      this.selected[selectionName] = []
      _.each(this.options[selectionName], x => {
        this.selected[selectionName].push(x.value)
      })
    },
    setNone: function (selectionName) {
      this.selected[selectionName] = []
    },
    filterReset: function () {
      _.each(this.staticDataProperties, property => {
        this.selected[property.property] = []
      })
      _.each(this.staticDataTaxonomy, (value) => {
        this.selected[value.key] = []
      })
    }
  },
  watch: {
    selected: {
      handler: function (val) {
        const filterSelected = this.$store.state.filterSelected
        filterSelected[this.namespace] = val
        this.$store.commit('setFilterSelected', filterSelected)
      },
      deep: true
    }
  }
}
</script>
