<template>
<div classs="bg-white">
  <div v-if="loading" class="text-white text-center">
    <transition name="fade">
      <div class="text-white text-center" :style="style">
        <nav class="navbar navbar-expand-lg navbar-light bg-white fixed-top justify-content-between navbar-top" style="height: 80px;">
          <img :src="$config.BASE_URL + '/lg.jpg'" width="107.5" height="43" />
        </nav>
        <h1 class="kstyle title justify-content-md-center" style="margin-top: 100px;">Loading ESG Metrics</h1>
        <i class="fa fa-spinner fa-pulse fa-lg fa-fw" style="font-size: 6em; margin-top: 100px;"></i>
        <div style="font-size: 1.5em; margin-top: 100px;">loaded: {{count}} metrics and {{ countDE }} data elements</div>
      </div>
    </transition>
  </div>
  <b-container fluid v-if="!loading" classs="bg-white">
      <b-row class="mb-4" style="background: #1E49E2; padding: 0px; height: 57px;">
        <b-col style="background: #1E49E2; height: 57px;">
          <!-- <div style="background: #1E49E2; font-size: 16px; color: white; height: 57px;"> -->
          <b-navbar style="background: #1E49E2; height: 57px;">
            <b-navbar-nav>
              <b-nav pills id="top-menu-bar" style="background: #1E49E2; font-size: 16px; color: white; height: 57px; line-height: 43px;">
                <b-nav-item :to="{ name: 'MetricsHome' }" exact exact-active-class="active">Home</b-nav-item>
                <b-nav-item :to="{ name: 'Interoperability' }" exact exact-active-class="active">Interoperability</b-nav-item>
                <b-nav-item :to="{ name: 'Lineage' }" :active="activeLineage">Lineage</b-nav-item>
                <b-nav-item :to="{ name: 'Metrics' }" exact exact-active-class="active">Metrics</b-nav-item>
                <b-nav-item v-if="activeSingleMetric" :active="activeSingleMetric">Metric</b-nav-item>
                <b-nav-item :to="{ name: 'DataElements' }" exact exact-active-class="active">Data Elements</b-nav-item>
                <b-nav-item v-if="activeSingleDataElement" :active="activeSingleDataElement">Single Data Element</b-nav-item>
                <b-nav-item :to="{ name: 'MetricsReportingframeworks' }" exact exact-active-class="active">Frameworks</b-nav-item>
                <b-nav-item v-if="activeSingleFramework" :active="activeSingleFramework">Single Framework</b-nav-item>
                <b-nav-item :to="{ name: 'MetricsGraphs' }" exact exact-active-class="active">Graphs</b-nav-item>
                <b-nav-item :to="{ name: 'DataModel' }" exact exact-active-class="active">Data model</b-nav-item>
                <b-nav-item v-if="$config.BUILD === 'full'" :to="{ name: 'MetricsDataStrats' }" exact exact-active-class="active">Data stratification</b-nav-item>
                <b-nav-item v-if="$config.BUILD === 'full'" :to="{ name: 'MetricsDownload' }" exact exact-active-class="active">Download</b-nav-item>
              </b-nav>
            </b-navbar-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav pills id="top-menu-bar" style="background: #1E49E2; font-size: 16px; color: white; height: 57px; line-height: 43px;">
                <b-nav-item @click="reload" v-b-popover.hover.top="'Reload data'">Reload <b-icon-arrow-clockwise v-if="!loadingBackground"></b-icon-arrow-clockwise><b-icon-arrow-clockwise animation="spin" v-if="loadingBackground"></b-icon-arrow-clockwise></b-nav-item>
              </b-nav>
            </b-navbar-nav>
          </b-navbar>
        </b-col>
      </b-row>
      <b-row v-if="disclaimer && $config.BUILD === 'full'">
      <b-col>
        <b-card class="my-3">
          <div class="mb-3">
            <h2>Risk disclaimer</h2>
            <div class="mb-3">
              <strong>This data is for KPMG internal use only. This is work in progress. Engagement Leaders are solely responsible for data provided to clients and they
              need to perform appropriate review and quality control before providing any data to clients or using any data in client services.</strong>
              <b-btn class="mr-2 ml-2" @click="disclaimer=false">OK</b-btn>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
      <b-row class="mb-4" v-if="ready">
        <b-col>
          <router-view></router-view>
        </b-col>
      </b-row>
  </b-container>
</div>
</template>

<script>
import _ from 'lodash'
import delay from '@/libs/delay'
import PouchDB from 'pouchdb'

import { BIconArrowClockwise } from 'bootstrap-vue'

let dbMetrics = new PouchDB('metrics')
let dbDataelements = new PouchDB('dataelements')

export default {
  name: 'MetricsLayout',
  components: {
    BIconArrowClockwise
  },
  computed: {
    activeSingleMetric: function () {
      let result = false
      if (this.$route.name === 'Metric') {
        result = true
      }
      return result
    },
    activeSingleDataElement: function () {
      let result = false
      if (this.$route.name === 'DataElement') {
        result = true
      }
      return result
    },
    activeSingleFramework: function () {
      let result = false
      if (this.$route.name === 'MetricsReportingframework') {
        result = true
      }
      return result
    },
    activeLineage: function () {
      let result = false
      if (this.$route.path.includes('lineage')) {
        result = true
      }
      return result
    },
    dataelements: {
      get () {
        return this.$store.state.dataelements
      },
      set (payload) {
        this.$store.commit('setDataelements', payload)
      }
    },
    metrics: {
      get () {
        return this.$store.state.metrics
      },
      set (payload) {
        this.$store.commit('setMetrics', payload)
      }
    },
    reportingframeworks: {
      get () {
        return this.$store.state.reportingframeworks
      },
      set (payload) {
        this.$store.commit('setReportingframeworks', payload)
      }
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    document.title = "ESG Metrics"
    this.$store.commit('setBrand', 'ESG Metrics')
    const image = _.sample(['fox', 'penguin', 'photographer', 'tent', 'wave', 'whale'])
    this.style = `background-image: url('/img/${image}.jpg'); background-repeat: no-repeat; background-size: cover; position: fixed; z-index: 10000; top: 66px; right: 0px; bottom: 0px; left: 0px;`
    await this.load()
  },
  data () {
    const data = {
      count: 0,
      countDE: 0,
      disclaimer: true,
      loading: true,
      loadingBackground: false,
      ready: false,
      style: ''
    }
    return data
  },
  methods: {
    load: async function () {
      this.loading = true
      this.ready = false
      let loadFromDb = false
      let reloadFromDb = false
      const infoDbMetrics = await dbMetrics.info()
      if (this.metrics.length === 0) {
        if (infoDbMetrics.doc_count === 0) {
          loadFromDb = true
        } else {
          let metrics = await dbMetrics.allDocs({ include_docs: true })
          metrics = metrics.rows.map(x => x.doc)
          this.count = metrics.length
          this.metrics = metrics
          reloadFromDb = true
        }
      }
      const infoDbDataelements = await dbDataelements.info()
      if (this.dataelements.length === 0) {
        if (infoDbDataelements.doc_count === 0) {
          loadFromDb = true
        } else {
          let dataelements = await dbDataelements.allDocs({ include_docs: true })
          dataelements = dataelements.rows.map(x => x.doc)
          this.countDE = dataelements.length
          this.dataelements = dataelements
          reloadFromDb = true
        }
      }
      if (loadFromDb) {
        await Promise.all([this.loadRows(), this.loadDataelements()])
        this.loading = false
        this.ready = true
      } else if (reloadFromDb) {
        this.loading = false
        this.ready = true
        this.loadingBackground = true
        await Promise.all([this.loadRows(), this.loadDataelements()])
        this.ready = false
        this.loadingBackground = false
        this.ready = true
      } else {
        this.loading = false
        this.ready = true
      }
      this.loading = false
    },
    loadDataelements: async function () {
      let rows = []
      try {
        const limit = 100
        let offset = 0
        let response = []
        let page = 0
        do {
          offset = page * limit
          response = await this.$Amplify.API.get('cosmos', `/dataelements/limit/${limit}/offset/${offset}`)
          const data = rows.concat(response)
          rows = data
          this.countDE = rows.length
          page++
          await this.$nextTick()
        } while (response.length === limit)
        this.dataelements = _.uniqBy(rows, 'id')
        const info = await dbDataelements.info()
        if (info.doc_count > 0) {
          await dbDataelements.destroy()
          dbDataelements = null
          dbDataelements = new PouchDB('dataelements')
        }
        await dbDataelements.bulkDocs(this.dataelements)
      } catch (e) {
        this.$logger.warn('saved ERROR: ', e)
      }
    },
    loadRows: async function () {
      let rows = []
      try {
        const limit = 100
        let offset = 0
        let response = []
        let page = 0
        do {
          offset = page * limit
          response = await this.$Amplify.API.get('cosmos', `/metrics/limit/${limit}/offset/${offset}`)
          const data = rows.concat(response)
          rows = data
          this.count = rows.length
          page++
          await this.$nextTick()
        } while (response.length === limit)
        this.metrics = _.uniqBy(rows, 'id')
        const reportingframeworks = this.metrics.map(x => x.reportingframeworks).flat()
        this.reportingframeworks = _.orderBy(_.uniqBy(reportingframeworks, 'id'), ['name'], ['asc'])
        const info = await dbMetrics.info()
        if (info.doc_count > 0) {
          await dbMetrics.destroy()
          dbMetrics = null
          dbMetrics = new PouchDB('metrics')
        }
        await dbMetrics.bulkDocs(this.metrics)
      } catch (e) {
        this.$logger.warn('saved ERROR: ', e)
      }
    },
    reload: async function () {
      this.loadingBackground = true
      try {
        await Promise.all([this.loadRows(), this.loadDataelements()])
      } catch (e) {
        this.$logger.warn('load ERROR: ', e)
      }
      this.ready = false
      this.loading = true
      await delay(200)
      this.loadingBackground = false
      this.loading = false
      this.ready = true
    }
  }
}
</script>
<style scoped>
#top-menu-bar a:hover {
  font-weight: bold;
}
#top-menu-bar li a {
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 0px;
}
#top-menu-bar li .active {
  background: #00B8F5;
  font-weight: bold;
}
</style>
