<template>
  <b-card class="mb-3">
    <router-link :to="{ name: 'Metric', params: { id: item.id }}"><h5 class="card-title">{{item.name}}</h5></router-link>
    <div v-if="item.dataelements.length > 0">
      <strong>Data elements: </strong>
      <span v-for="(element, index) in item.dataelements" :key="item.id + '-' + element.id + '-' + index">
        <router-link :to="{ name: 'DataElement', params: { id: element.id }}">{{ element.name }},</router-link>
      </span>
    </div>
    <div v-if="item.taxonomy.length > 0">
      <in-line :rows="item.taxonomy"></in-line>
    </div>
    <div v-if="item.reportingframeworks.length > 0">
      <strong>Reporting frameworks: </strong>
      <span v-for="(framework, index) in item.reportingframeworks" :key="item.id + '-' + framework.id + '-' + index">
        <router-link :to="{ name: 'MetricsReportingframework', params: { id: framework.id }}"> {{ framework.name }},</router-link>
      </span>
    </div>
  </b-card>
</template>

<script>
import InLine from '@/components/taxonomy/InLine.vue'

export default {
  name: 'MetricSimple',
  props: ['item'],
  components: {
    InLine
  },
  computed: {
  },
  created: function () {
  },
  data () {
    const data = {
      filter: ''
    }
    return data
  },
  methods: {
  }
}
</script>
