<template>
  <span v-html="html"></span>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'InitiativeTaxonomy',
  props: ['rows'],
  computed: {
    rowsShow: function () {
      let results = []
      let parents = []
      _.each(this.rows, row => {
        parents = parents.concat(row.parents)
      })
      results = _.uniqBy(parents, 'id')
      _.each(results, parent => {
        parent.children = []
        _.each(this.rows, row => {
          if (row.parents.find(e => e.id === parent.id)) {
            parent.children.push({ name: row.name, id: row.id })
          }
        })
      })
      return results
    },
    html: function () {
      let results = ''
      _.each(this.rowsShow, group => {
        results += `<strong>${group.name}</strong>: `
        _.each(group.children, child => {
          results = results + child.name + ', '
        })
      })
      return results
    }
  }
}
</script>
